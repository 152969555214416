import React from "react";
import Routing from "./Routing";

const App = () => {
  return (
    <>
      {" "}
      <Routing />
    </>
  );
};

export default App;
