/* This example requires Tailwind CSS v2.0+ */
import React from "react";
// import { Link } from "react-router-dom";
import { ReactComponent as HeroIllustration } from "assets/customImages/heros/Sandeep.svg";
// import { ReactComponent as FreeLancerCuate } from "assets/customImages/heros/Freelancer-cuate.svg";
// import { ReactComponent as FreeLancerAmico } from "assets/customImages/heros/Freelancer-amico.svg";
// import { ReactComponent as FreeLancerRafiki } from "assets/customImages/heros/Freelancer-rafiki.svg";
// import { ReactComponent as WorkTimePana } from "assets/customImages/heros/Work time-pana.svg";
import SandeepResume from "assets/docs/SandeepWeb3.pdf";
// import ImageCarousel from "./ImageCarousel";

export default function Home() {
  return (
    <>
      <section className="px-2 py-10  bg-white md:px-0">
        <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
          <div className="flex flex-wrap items-center sm:-mx-3 h-auto">
            <div className="w-full md:w-1/2 md:px-3">
              <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                <h1 className="text-5xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl">
                  <span className="block xl:inline">Hey Folks 🙋, This is </span>
                  <br className="hidden md:block" />
                  <span className="w-full text-transparent bg-clip-text bg-gradient-to-r from-yellow-400  to-purple-500 lg:inline">
                    Sandeep Kumar
                  </span>
                </h1>
                <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
                  I am a 25 years old Full Stack Web Developer 💻 based out of Bangalore. I describe myself as a passionate developer who loves ❤️
                  coding and coffee ☕ and loves to travel on weekends.
                </p>
                <div className="relative flex flex-col sm:flex-row sm:space-x-4">
                  <a
                    href={SandeepResume}
                    rel="noreferrer"
                    target="_blank"
                    className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-yellow-600 rounded-md sm:mb-0 hover:bg-indigo-700 sm:w-auto">
                    View My Resume
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 ml-1"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round">
                      <line x1="5" y1="12" x2="19" y2="12" />
                      <polyline points="12 5 19 12 12 19" />
                    </svg>
                  </a>
                  {/* <Link
                    to="/verify"
                    className="flex items-center px-6 py-3 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600">
                    Learn more
                  </Link> */}
                </div>
              </div>
            </div>
            {/* <ImageCarousel /> */}

            <div className="w-full md:w-1/2">
              <div className="w-full h-auto overflow-hidden sm:rounded-xl">
                <HeroIllustration />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
