import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

export default function Example() {
  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@satyasandeep007");
        setBlogs(response.data.items);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-10 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-lg font-medium text-gray-900">Blogs</h2>
        <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
          {blogs &&
            blogs.map((blog) => (
              <div key={blog.link} className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden">
                <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden bg-gray-100">
                  <img src={blog.thumbnail} alt={blog.title} className="w-full h-full object-center object-cover sm:w-full sm:h-full" />
                </div>
                <div className="flex-1 p-4 space-y-2 flex flex-col">
                  <h3 className="text-sm font-medium text-gray-900">
                    <a href={blog.link} rel="noreferrer" target="_blank">
                      <span aria-hidden="true" className="absolute inset-0" />
                      {blog.title}
                    </a>
                  </h3>
                  <p className="text-sm text-gray-500">Published on : {moment(blog.pubDate).format("YYYY-MM-DD")}</p>
                  <div className="flex-1 flex flex-col justify-end">
                    {/* <p className="text-sm italic text-gray-500">{blog.categories}</p> */}
                    {/* <p className="text-base font-medium text-gray-900">{blog.price}</p> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
