import React from "react";
import GPS_TRACKING_IMAGE from "assets/customImages/GPS_TRACKING.png";
import PTL_HOME_IMAGE from "assets/customImages/PTL_HOME.png";
import BMS_ACCOUNTS_MAIN_IMAGE from "assets/customImages/BMS_ACCOUNTS_MAIN.png";
import YO_DAILY_ADMIN_DASHBOARD from "assets/customImages/YO_DAILY_ADMIN_DASHBOARD.png";

import CERTIFY from "assets/customImages/certify.png";
import FUND_ME_ON_ETH from "assets/customImages/fundmeoneth.png";
import MEBLOC from "assets/customImages/mebloc.png";
import NFT_MART from "assets/customImages/nft-mart.jpeg";

const web2Projects = [
  {
    id: 1,
    name: "GPS Tracking",
    href: "#",
    price: "$256",
    description:
      "Internal Application developed for live tracking of all vehicles which are having gps device fixed. People can generate reports in excel format for selected time range.",
    options: "Internal",
    imageSrc: GPS_TRACKING_IMAGE,
    imageAlt: "Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green."
  },
  {
    id: 2,
    name: "Client Dashboard",
    href: "#",
    price: "$32",
    description:
      "A Platform developed for our clients where they can place order and can track the order from pickup to delivery. Our supervisors can download auto generated shipping labels and stick them to orders.",
    options: "Logistics",
    imageSrc: PTL_HOME_IMAGE,
    imageAlt: "Front of plain black t-shirt."
  },
  {
    id: 3,
    name: "Billing Management System",
    href: "#",
    price: "$32",
    description:
      "It is an internal Tool, where employees raise day to day expenses as tickets with attachments and Finance team have access to all tickets and process the payment after getting approval from managers.",
    options: "Finance",
    imageSrc: BMS_ACCOUNTS_MAIN_IMAGE,
    imageAlt: "Front of plain black t-shirt."
  },
  {
    id: 4,
    name: "YoDaily Admin Dashboard",
    href: "#",
    price: "$32",
    description:
      "An Internal Admin Dashboard developed for an ecommerce project as a freelance Project which delivers day to day milk projects to customers in Ongole Town, Andhra Pradesh.",
    options: "E-Commerce",
    imageSrc: YO_DAILY_ADMIN_DASHBOARD,
    imageAlt: "Front of plain black t-shirt."
  }
];

const web3Projects = [
  {
    id: 1,
    name: "Certifi",
    href: "https://certifi-novobloc.netlify.app",
    price: "$256",
    description: "A Decentralized platform where Issuers can issue a certificate against user & can revoke at any time.",
    options: "Hackathon",
    imageSrc: CERTIFY,
    imageAlt: "Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green."
  },
  {
    id: 2,
    name: "Fund Me On ETH",
    href: "https://fundmeoneth.netlify.app",
    price: "$32",
    description: "A Decentralized platform where users can donate their favorite content creators in ETH ",
    options: "Hackathon",
    imageSrc: FUND_ME_ON_ETH,
    imageAlt: "Front of plain black t-shirt."
  },
  {
    id: 3,
    name: "Mebloc",
    href: "https://mebloc.netlify.app",
    price: "$32",
    description: "A Decentralized platform where patient medical data can be shared across blockchain with consent of user",
    options: "Hackathon",
    imageSrc: MEBLOC,
    imageAlt: "Front of plain black t-shirt."
  },
  {
    id: 4,
    name: "NFT Mart",
    href: "https://nft-mart.netlify.app",
    price: "$32",
    description: "A NFT Market Place where users can Buy, Sell NFTs at 0 gas fees on ethereum blockchain",
    options: "Personal",
    imageSrc: NFT_MART,
    imageAlt: "Front of plain black t-shirt."
  }
];

export default function Example() {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-10 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-lg font-medium text-gray-900">Web2 Projects</h2>
        <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
          {web2Projects.map((product) => (
            <div
              key={product.id}
              className="group relative bg-white border border-gray-200 rounded-lg hover:opacity-75 flex flex-col overflow-hidden">
              <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden bg-gray-100">
                <img src={product.imageSrc} alt={product.imageAlt} className="w-full h-full object-center object-cover sm:w-full sm:h-full" />
                <div className="flex items-end opacity-0 p-4 group-hover:opacity-100" aria-hidden="true">
                  <div className="w-full bg-white bg-opacity-75 backdrop-filter backdrop-blur py-2 px-4 rounded-md text-sm font-medium text-gray-900 text-center">
                    View Product
                  </div>
                </div>
              </div>
              <div className="flex-1 p-4 space-y-2 flex flex-col">
                <h3 className="text-sm font-medium text-gray-900">
                  <a href={product.href} rel="noreferrer" target="_blank">
                    <span aria-hidden="true" className="absolute inset-0" />
                    {product.name}
                  </a>
                </h3>
                <p className="text-sm text-gray-500">{product.description}</p>
                <div className="flex-1 flex flex-col justify-end">
                  <p className="text-sm italic text-gray-500">{product.options}</p>
                  {/* <p className="text-base font-medium text-gray-900">{product.price}</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="max-w-2xl mx-auto py-10 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-lg font-medium text-gray-900">Web3 Projects</h2>
        <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
          {web3Projects.map((product) => (
            <div key={product.id} className="group relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden">
              <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden bg-gray-100">
                <img src={product.imageSrc} alt={product.imageAlt} className="w-full h-full object-center object-cover sm:w-full sm:h-full" />
              </div>
              <div className="flex-1 p-4 space-y-2 flex flex-col">
                <h3 className="text-sm font-medium text-gray-900">
                  <a href={product.href} rel="noreferrer" target="_blank">
                    <span aria-hidden="true" className="absolute inset-0" />
                    {product.name}
                  </a>
                </h3>
                <p className="text-sm text-gray-500">{product.description}</p>
                <div className="flex-1 flex flex-col justify-end">
                  <p className="text-sm italic text-gray-500">{product.options}</p>
                  {/* <p className="text-base font-medium text-gray-900">{product.price}</p> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
