import React from "react";
import { Link } from "react-router-dom";
// import mainLogo from "./logo.png";
import SandeepResume from "assets/docs/SandeepWeb3.pdf";

const paths = [
  {
    path: SandeepResume,
    name: "Resume"
  },
  {
    path: "https://www.youtube.com/channel/UCFZKGwDsklqZNFz_MdGHGng",
    name: "Youtube"
  }
];

const linkPaths = [
  {
    path: "/projects",
    name: "Projects"
  },
  {
    path: "/blogs",
    name: "Blogs"
  }
];

export default function Header() {
  return (
    <section className="w-full px-8 text-gray-700 bg-white">
      <div className="container flex flex-col flex-wrap items-center justify-between py-5 mx-auto md:flex-row max-w-7xl">
        <div className="relative flex flex-col md:flex-row">
          <Link to="/" className="flex items-center mb-5 font-medium text-gray-900 lg:w-auto lg:items-center lg:justify-center md:mb-0">
            {/* <img src={mainLogo} className="w-12 h-12" alt="fireSpot" /> */}
            <span className="mx-auto ml-0 text-xl font-black leading-none text-gray-900 select-none">
              Sandeep&nbsp;
              <span className="w-full text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-purple-500 lg:inline">Kumar</span>
              <span className="text-indigo-600"></span>
            </span>
          </Link>
          <nav className="flex flex-wrap items-center mb-5 text-base md:mb-0 md:pl-8 md:ml-8 md:border-l md:border-gray-200">
            {linkPaths.map(({ path, name }) => (
              <Link key={path + name} to={path} className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900">
                {name}
              </Link>
            ))}

            {paths.map(({ path, name }) => (
              <a
                key={path + name}
                href={path}
                target="_blank"
                rel="noreferrer"
                className="mr-5 font-medium leading-6 text-gray-600 hover:text-gray-900">
                {name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </section>
  );
}
