import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

/** Layout */
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

import Home from "./pages/home/Home";
import Projects from "./pages/projects/Projects";
import Blogs from "./pages/blogs/Blogs";
/** Pages */

function Routing() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/blogs" element={<Blogs />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default Routing;
